import React, {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Col, Row, Card, Form} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import Footer from "../components/Footer";
import {useNavigate} from "react-router-dom";
import DropdownItemsInput from "../components/DropdownItemsInput";
import WyEditor from "../components/WYEditor";
import InfoCircle from "../components/Icons/InfoCircle";
import AlertModal from "../components/AlertModal";
import DeleteModal from "../components/DeleteModal";
import {getAllLetterTemplate} from "../actions/LetterNoticesActions";
import Loader from "../components/Loader";
import {getTeacherAssociates} from "../actions/ConfigActions";
import { IoMdAddCircleOutline } from "react-icons/io";

const SendLetters = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const searchRef = useRef();
    const [departmentCheck, setDepartmentCheck] = useState([])
    const [teachers, setTeacher] = useState([])
    const [departments, setDepartment] = useState([])
    const [alertModal, setAlertModal] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const [userCheck, setUserCheck] = useState([])
    const [userEmail, setUserEmail] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100)
    const [templatesData, setTemplatesData] = useState([])
    const [userView, setUserView] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState(teachers)
    const [fieldIsValid, setFieldIsValid] = useState({
        isTemplateValid: false,
        isUserValid: false,
        isTextValid: false,
    });
    const [fieldData, setFieldData] = useState({
        templateIdData: "",
        templateTextData: "",
        templateTextDataRaw: "",
    })
    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState([]);


    const openLink = () => {
        history('/letters-notices')
    }

    useEffect(() => {
        dispatch(getTeacherAssociates())
    }, []);

    useEffect(() => {
        dispatch(getAllLetterTemplate(pageSize, pageNumber))
    }, [pageNumber]);

    const getAllLettersTemplate = useSelector(state => state.getAllLettersTemplate)
    const {error, loading, letterTemplateInfo} = getAllLettersTemplate

    useEffect(() => {
        setTemplatesData([])
        if (!getAllLettersTemplate.letters_template_list <= 0) {
            getAllLettersTemplate.letters_template_list.map((item, index) => {
                setTemplatesData((prevData) => [
                    ...prevData,
                    {
                        id: item.letterTemplateId,
                        data: item.title,
                        descriptions: item.descriptions,
                        documentText: item.documentText,
                        isHardcopyRequired: item.isHardcopyRequired,
                        createdDate: item.createdDate,
                    }
                ])
            });
        }
    }, [getAllLettersTemplate]);

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                        employee_Personal_Email: item.employee_Personal_Email,
                        employee_Work_Email: item.employee_Work_Email,
                        preferred_email: item.preferred_email,
                        employee_Department_Code: item.employee_Department_Code,
                        dept_Desc: item.dept_Desc,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);

    const getAllDepartmentList = useSelector(state => state.getAllDepartment)
    const {department_list} = getAllDepartmentList

    useEffect(() => {
        if (!getAllDepartmentList.department_list <= 0) {
            // console.log(getTeacherList.teacher_list)
            getAllDepartmentList.department_list.map((item, index) => {
                setDepartment((prevData) => [
                    ...prevData,
                    {
                        id: item.deptDesc,
                        data: item.deptCode,
                    }
                ])
            });
        }
    }, [getAllDepartmentList.department_list]);


    useEffect(() => {
        if (departmentCheck.length <= 0) {
            setUserView(false)
        } else if (departmentCheck.includes(0)) {
            setUserView(true)
            setFilteredUsers(teachers)
        } else if ((departmentCheck.length > 0) && (!departmentCheck.includes(0))) {
            setUserView(true)
            const filteredData = teachers.filter((item) =>
                departmentCheck.toString().includes(item.dept_Desc)
            );
            setFilteredUsers(filteredData)
        }
    }, [departmentCheck]);

    useEffect(() => {
        if (userCheck.length <= 0) {
            setFieldIsValid({
                ...fieldIsValid,
                isUserValid: false,
            });
        }
        if (userCheck.length > 0) {
            setFieldIsValid({
                ...fieldIsValid,
                isUserValid: true,
            });
        }
    }, [userCheck]);

    const continueLink = () => {
        if (userCheck.length <= 0 && emails.length <= 0) {
            setAlertModal(true)
        }
        if (userCheck.length > 0 || emails.length > 0) {
            if (userEmail.length > 0) {
                setInfoModal(true)
                setAlertModal(false)
            } else {
                setAlertModal(false)
                history('/letters-preview', {
                    state: {
                        emails: emails,
                        userList: userCheck,
                        emailCheck: userEmail,
                        templateId: fieldData.templateIdData,
                        templateText: fieldData.templateTextData,
                        templateTextRaw: fieldData.templateTextDataRaw
                    }
                })
            }
        }
    }

    const checkUserEmail = (id) => {
        const employee = teachers.find(emp => emp.id === id);
        if (!employee) {
            setUserEmail(prevState => [
                ...prevState,
                {
                    id: id,
                    email: false,
                    name: ""
                }
            ])
        } else {
            if (employee.employee_Personal_Email === "" && employee.employee_Work_Email === "") {
                setUserEmail(prevState => [
                    ...prevState,
                    {
                        id: id,
                        email: false,
                        name: employee.data
                    }])
            }
        }
    }

    function updateFilters(checked, element) {
        if (checked)
            if (element === 0) {
                setDepartmentCheck(prev => [
                    ...prev,
                    0
                ])
                department_list.map((item) => {
                    setDepartmentCheck(prevState => [
                        ...prevState,
                        item.deptDesc
                    ])
                })
            } else {
                setDepartmentCheck([
                    ...departmentCheck,
                    element
                ])
            }
        if (!checked) {
            if (element === 0) {
                setDepartmentCheck([])
                setUserCheck([])
            } else {
                const indexToDelete = departmentCheck.indexOf(element)
                setDepartmentCheck(prevState => prevState.filter((_, index) => index !== indexToDelete));

                const indexToDeleteZero = departmentCheck.indexOf(0)
                setDepartmentCheck(prevState => prevState.filter((_, index) => index !== indexToDeleteZero));
            }
        }
    }

    const selectUsers = (checked, element, personalEmail, workEmail) => {
        if (checked)
            if (element === 0) {
                setUserCheck(prev => [
                    ...prev,
                    0
                ])
                filteredUsers.map((item) => {
                    setUserCheck(prevState => [
                        ...prevState,
                        item.id
                    ])
                    checkUserEmail(item.id)
                })
            } else {
                setUserCheck([
                    ...userCheck,
                    element
                ])
                checkUserEmail(element)
            }
        if (!checked) {
            if (element === 0) {
                setUserCheck([])
                setUserEmail([])
            } else {
                const indexToDelete = userCheck.indexOf(element)
                setUserCheck(prevState => prevState.filter((_, index) => index !== indexToDelete));
                setUserEmail(userEmail.filter(user => user.id !== element))

                const indexToDeleteZero = userCheck.indexOf(0)
                setUserCheck(prevState => prevState.filter((_, index) => index !== indexToDeleteZero));
            }
        }
    };
    const handleAddEmail = () => {
        if (email && validateEmail(email) && !emails.includes(email)) {
            setEmails([...emails, email]);
            setEmail(""); // Clear input after adding
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        setEmails(emails.filter((e) => e !== emailToRemove));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const showDepartment = (data) => {
        return data.map((item, index) => {
            try {
                return (
                    <Row className="background-1 mt-2">
                        <Form.Check
                            className="template-check"
                            name="department"
                            label={item.data}
                            type="checkbox"
                            id={item.id}
                            checked={departmentCheck.includes(item.id) == true ? true : false}
                            onChange={(e) => updateFilters(e.target.checked, item.id)}
                        />
                    </Row>
                )
            } catch (e) {
                alert(e.message);
            }
        })
    }
    const showUsers = (data) => {
        return data.map((item, index) => {
            try {
                return (
                    <Row className="background-1 mt-2">
                        <Form.Check
                            className="template-check"
                            name="users"
                            label={item.data}
                            type="checkbox"
                            id={item.id}
                            checked={userCheck.includes(item.id) == true ? true : false}
                            // checked={departmentCheck == item.id ? true : false}
                            onChange={(e) => selectUsers(e.target.checked, item.id, item.employee_Personal_Email, item.employee_Work_Email)}
                        />
                    </Row>
                )
            } catch (e) {
                alert(e.message);
            }
        })
    }

    const handleSearchChange = (event) => {
        event.preventDefault();
        const searchValue = searchRef.current.value.toLowerCase();
        const filteredData = teachers.filter((item) =>
            item.data.toString().toLowerCase().includes(searchValue)
        );
        setFilteredUsers(filteredData);
    }

    const templateData = (data) => {
        const letterTemplate = templatesData.find(item => item.id === data.id);
        if (letterTemplate) {
            setFieldData({
                ...fieldData,
                templateIdData: data.id,
                templateTextData: letterTemplate.documentText
            })
            setFieldIsValid({
                ...fieldIsValid,
                isTemplateValid: true,
                isTextValid: true
            })
        }
    }

    const checkEditor = (data) => {
        if (data[0].length > 10) {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: true,
            });
            setFieldData({
                ...fieldData,
                templateTextDataRaw: data[1]
            })
            return;
        } else {
            setFieldIsValid({
                ...fieldIsValid,
                isTextValid: false,
            });
        }
    };
    const closeAlert = (data) => {
        setAlertModal(false)
    }
    const importResult = (data) => {
        if (data == 1) {
            history('/letters-preview', {
                state: {
                    emails: emails,
                    userList: userCheck,
                    emailCheck: userEmail,
                    templateId: fieldData.templateIdData,
                    templateText: fieldData.templateTextData,
                    templateTextRaw: fieldData.templateTextDataRaw
                }
            })
        }
        if (data == 0) {
            setInfoModal(false)
        }
    }

    const pageName = 'letters';
    return (
        <>
            {loading && <Loader/>}
            <Row className="sidebar-background">
                {alertModal &&
                    <AlertModal heading="Select user to send Letter/Notice" type="error" result={closeAlert}/>}
                {infoModal && <DeleteModal heading="Missing an email address"
                                           type="import"
                                           button_true="Continue"
                                           button_false="Cancel"
                                           add_on="true"
                                           user_list={userEmail}
                                           question="These selected users are missing an email address and will not receive the letters. Do you wish to continue?"
                                           answer=""
                                           result={importResult}
                />}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="send-letters" title="Back" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Row className="background-1 mt-4">
                            <Col lg={{span: 6}} md={{span: 6}}>
                                <Row className="background-1">
                                    <div className="card-body-letters mb-5">
                                        <div className="row background-1 header-div">
                                            Select User
                                        </div>
                                        <div className="row background-1 body-div pt-3">
                                            <Col lg={{span: 5}} md={{span: 5}} className="letter-user-department pt-5">
                                                <Row className="background-1 mt-2">
                                                    <Form.Check
                                                        className="template-check"
                                                        name="department"
                                                        label="Select All"
                                                        type="checkbox"
                                                        id="0"
                                                        checked={departmentCheck.includes(0) == true ? true : false}
                                                        onChange={(e) => updateFilters(e.target.checked, 0)}
                                                    />
                                                </Row>
                                                {showDepartment(departments)}
                                            </Col>
                                            {userView &&
                                                <Col lg={{span: 7}} md={{span: 7}} className=" ps-4">
                                                    <form>
                                                        <input ref={searchRef} onChange={handleSearchChange}
                                                               type="search" autoComplete="off"
                                                               placeholder="Search User"
                                                               className="mb-3 nosubmit"
                                                               id="search" aria-describedby="emailHelp"
                                                        />
                                                    </form>
                                                    <div className="row background-1 scroll-div">
                                                        <Row className="background-1 mt-2">
                                                            <Form.Check
                                                                className="template-check"
                                                                name="users"
                                                                label="Select All"
                                                                type="checkbox"
                                                                id="0"
                                                                checked={userCheck.includes(0) == true ? true : false}
                                                                onChange={(e) => selectUsers(e.target.checked, 0, null, null)}
                                                            />
                                                        </Row>
                                                        {showUsers(filteredUsers)}
                                                    </div>
                                                </Col>
                                            }
                                        </div>
                                    </div>
                                </Row>
                                <Row className="background-1">
                                    <div className="card-body-letters mb-5">
                                        <div className="row background-1 header-div">
                                            Add Email
                                        </div>
                                        <div className="row background-1 body-div-email pt-3">
                                            <div className="pt-3 pb-3">
                                                <Row className="background-1">
                                                    <Col lg={{span: 8}} md={{span: 4}} sm={{span: 4}}>
                                                        <input
                                                            type="text" autoComplete="off"
                                                            className="input-document input-spacing-document"
                                                            placeholder="Add email address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            id="document_title"/>
                                                    </Col>
                                                    <Col lg={{span: 4}} md={{span: 4}} sm={{span: 4}}>
                                                        <div className="float-right-child add-button button-solid reset-filter-button"
                                                             onClick={handleAddEmail}>
                                                            Add
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div style={{marginTop: "10px"}}>
                                                    {emails.map((email) => (
                                                        <div
                                                            key={email}
                                                            className="letters-emails-div">
                                                            {email}{" "}
                                                            <span
                                                                onClick={() => handleRemoveEmail(email)}
                                                                className="letters-emails-span">✕</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                            <Col lg={{span: 6}} md={{span: 6}}>
                                <Card className="card-body-letters mb-5 pb-2">
                                    <div className="row background-1 header-div">
                                        Send Letters
                                    </div>
                                    <div className="row background-1 body-div pt-3 pb-2 scroll-div">
                                        <Row className="background-1">
                                            <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                 className="padding-left-unset padding-right-unset">
                                                <div className="template-list">
                                                    <label htmlFor="document_title" id="teacher-associate"
                                                           className="form-label form-spacing">
                                                        Select Template<span className="asterics">*</span></label>
                                                    <div>
                                                        <DropdownItemsInput data={templatesData} result={templateData}
                                                                            value={fieldData.templateIdData}
                                                                            className={!fieldIsValid.isTemplateValid ?
                                                                                "background-1 dropdown-input-content" : "background-1 dropdown-input-content-valid"}
                                                                            title="Select Template"/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="background-1">
                                            <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                 className="padding-left-unset padding-right-unset">
                                                <WyEditor para={fieldData.templateTextData} result={checkEditor}/>
                                            </Col>

                                        </Row>
                                        <Row className="background-1 mt-2 mb-2">
                                            <div className="error-message-div">
                                                <InfoCircle className="sidebar-icon-form" color="var(--red)"/>
                                                <span> Note: Add appropriate data in preview.</span>
                                            </div>
                                        </Row>
                                        {(
                                                !fieldIsValid.isTextValid
                                                // || !fieldIsValid.isUserValid
                                            )
                                            &&
                                            <Row className="background-1 mt-3 mb-4">
                                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                     className="padding-left-unset padding-right-unset">
                                                    <div
                                                        className="button-solid float-left-child button-transparent-inactive button-size me-1"
                                                        onClick={openLink}>
                                                        Cancel
                                                    </div>
                                                    <div
                                                        className="button-solid float-right-child button-inactive button-size ms-1">
                                                        Continue
                                                    </div>
                                                </Col>
                                            </Row>}
                                        {(
                                                fieldIsValid.isTextValid
                                                // && fieldIsValid.isUserValid
                                            )
                                            &&
                                            <Row className="background-1 mt-3 mb-4">
                                                <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                                     className="padding-left-unset padding-right-unset">
                                                    <div
                                                        className="button-solid button-transparent-active
                                                        float-left-child button-size me-1"
                                                        onClick={openLink}>
                                                        Cancel
                                                    </div>
                                                    <div className="button-solid button-active
                                                    float-right-child button-size ms-1"
                                                         onClick={continueLink}
                                                    >
                                                        Continue
                                                    </div>
                                                </Col>
                                            </Row>}
                                    </div>
                                </Card>
                            </Col>
                            <Footer/>
                        </Row>
                    </Row>

                </div>
            </Row>
        </>
    );
}

export default SendLetters;