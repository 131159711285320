import React, {useEffect, useRef, useState} from 'react';
import "../assets/css/modal.css"
import Loader from "./Loader";
import {IoClose} from "react-icons/io5";
import {Col, Row} from "react-bootstrap";
import WYEditorReadOnly from "./WYEditorReadOnly";

function EditorModal(props) {
    function toggleYes() {
        props.result(0)
    }
    const checkEditor = (data) => {

    };

    return (
        <>
            <div className="darkBG" data-backdrop="static">
                <div className={"modal-content"}>
                    <div className="modal-header">
                        <div className="modal-heading-title">
                            {props.heading}
                        </div>
                        <div className="modal-heading-title" onClick={toggleYes}>
                            <IoClose/>
                        </div>
                    </div>
                    <div className="body-text-form-read">
                        <Row className="background-1">
                            <Col lg={{span: 12, offset: 0}} md={{span: 12, offset: 0}}
                                 className="padding-left-unset padding-right-unset">
                                <WYEditorReadOnly para={props.document} result={checkEditor}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditorModal;