import React, {useEffect, useMemo, useState} from 'react';
import {EditorState, convertToRaw, convertFromHTML, ContentState, Modifier} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import '../assets/css/react-draft.css'
import {Col, Row} from "react-bootstrap";
import DropdownItemsInput from "./DropdownItemsInput";
import {useSelector} from "react-redux";

function WYEditorReadOnly(props) {
    const [dynamicValue, setDynamicValue] = useState([])

    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(props.para == undefined ? "" : `${props.para}`)
            )
        ),
    );

    const getDynamicList = useSelector(state => state.getDynamic)
    const {dynamic_list} = getDynamicList

    useEffect(() => {
        if (!getDynamicList.dynamic_value <= 0) {
            getDynamicList.dynamic_value.map((item, index) => {
                setDynamicValue((prevData) => [
                    ...prevData,
                    {
                        id: index,
                        data: `{{${item}}}`
                    }
                ])
            });
        }
    }, [getDynamicList.dynamic_value]);


    const htmlData = useMemo(
        () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
        [editorState]
    );
    const getEditorContent = () => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        console.log(rawContent);
    };

    const handleChange = (data) => {
        setEditorState(data);
    }
    const handleDropdownChange = (value) => {
        // Modify the editor state to insert the selected value
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            value.data,
        );
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters',
        );
        setEditorState(newEditorState);
    };

    useEffect(() => {
        props.result([draftToHtml(convertToRaw(editorState.getCurrentContent())).toString(), htmlData])
    }, [editorState]);

    useEffect(() => {
        // alert(props.para)
        if (props.para) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(`${props.para}`)
                )
            ))
        }
    }, [props.para]);

    return (
        <>
            {props.dynamicValue && <Row className="background-1">

                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                </Col>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-right-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="certificateNameLabel"
                               className="form-label form-spacing">
                            Dynamic Values<span className="asterics"></span></label>
                        <div>
                            <DropdownItemsInput data={dynamicValue}
                                                result={handleDropdownChange}
                                                className="background-1 background-1 dropdown-input-content"
                                                title="Select Dynamic Value"/>
                        </div>
                    </div>
                </Col>
            </Row>}
            {/*<CustomToolbar />*/}
            <Editor
                placeholder="Enter Document Text"
                editorState={editorState}
                onEditorStateChange={handleChange}
                readOnly
                toolbarHidden={true}
                wrapperClassName={draftToHtml(convertToRaw(editorState.getCurrentContent())).toString().length > 10 ? "wrapper-editor" : "wrapper-editor-error"}
            />
        </>
    );
}

export default WYEditorReadOnly;