import React from 'react';
import {useDispatch} from "react-redux";
import AddCircle from "./Icons/AddCircle";
import LeftArrow from "./Icons/LeftArrow";
import {useNavigate} from "react-router-dom";
import Send from "./Icons/Send";
import Processes from "./Icons/Processes";
import {exportAllCertificate} from "../actions/CertificateActions";
import DocumentUpload from "./Icons/DocumentUpload";
import Trash from "./Icons/Trash";
import DocumentDownload from "./Icons/DocumentDownload";

function Heading(props) {
    const history = useNavigate()
    const dispatch = useDispatch()
    const addDoc = () => {
        history('/add-documents')
    }
    const addCertificate = () => {
        history('/add-certificate')
    }
    const exportCertificate = () => {
        dispatch(exportAllCertificate())
    }
    const importCertificate = () => {
        props.result(true)
    }
    const addSchoolYear = () => {
        history('/create-school-year')
    }
    const createTemplate = () => {
        history('/create-new-template')
    }
    const createProcesses = () => {
        history('/create-process-template')
    }
    const sendLettersNotices = () => {
        history('/send-letters-notices')
    }
    const sendProcess = () => {
        history('/send-process')
    }
    const viewCalendar = () => {
        history('/calendar')
    }
    const viewClients = () => {
        history('/client-management')
    }
    const addAdvisor = (client_id) => {
        history(`/trusted-advisor-add`, {state: {clientName: props.title, client_id: props.client_id}})
    }
    const editAdvisor = (client_id) => {
        history(`/trusted-advisor-edit/1`, {state: {clientName: props.title, client_id: props.client_id}})
    }
    const returnTeacher = () => {
        props.result()
    }
    const viewDashboard = () => {
        history('/dashboard')
    }
    const addSuperAdmin = () => {
        history('/super-admin-add')
    }
    const addClient = () => {
        history('/client-add')
    }

    function headingReturn() {
        props.result(true)
    }
    function headingDelete() {
        props.result(0)
    }
    function headingDeleteClient() {
        props.result(0)
    }
    function headingDeleteAdvisor() {
        props.result(0)
    }
    function templateReturn(data) {
        props.result(data)
    }
    const downloadDocument = (data) => {
        window.open("/CertificateImportTemplate.xlsx", '_blank')
    }

    return (
        <>
            {props.group == "doc_list" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
                <div className="float-right-child add-button" onClick={() => addDoc()}>
                    <AddCircle classname="sidebar-icon" color="var(--white)"/> Add Document
                </div>
            </div>}
            {props.group == "super_admin_list" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
                <div className="float-right-child add-button" onClick={() => addSuperAdmin()}>
                    <AddCircle classname="sidebar-icon" color="var(--white)"/> Create Super Admin
                </div>
            </div>}
            {props.group == "client_list" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
                <div className="float-right-child add-button" onClick={() => addClient()}>
                    <AddCircle classname="sidebar-icon" color="var(--white)"/> Add Client
                </div>
            </div>}
            {props.group == "analytics" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
            </div>}
            {props.group == "certificate_list" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
                <div className="button-container certificate-button-layout">
                    <div className="add-button" onClick={() => importCertificate()}>
                        <DocumentUpload classname="sidebar-icon" color="var(--white)" /> Import
                    </div>

                    <div className="add-button" onClick={() => downloadDocument()}>
                        <DocumentDownload classname="sidebar-icon" color="var(--white)" /> Download Template
                    </div>

                    <div className="add-button" onClick={() => exportCertificate()}>
                        <DocumentDownload classname="sidebar-icon" color="var(--white)" /> Export
                    </div>
                    <div className="add-button" onClick={() => addCertificate()}>
                        <AddCircle classname="sidebar-icon" color="var(--white)"/> Add Certificate
                    </div>
                </div>
                {/*<div className="row background-1 child-width-certificate float-right-child">*/}
                {/*    <div className="col-3 add-button" onClick={() => exportCertificate()}>*/}
                {/*        <DocumentUpload classname="sidebar-icon" color="var(--white)"/> Import*/}
                {/*    </div>*/}

                {/*    <div className="col-3 add-button" onClick={() => exportCertificate()}>*/}
                {/*        <DocumentUpload classname="sidebar-icon" color="var(--white)"/> Export*/}
                {/*    </div>*/}

                {/*    <div className="col-5 add-button" onClick={() => addCertificate()}>*/}
                {/*        <AddCircle classname="sidebar-icon" color="var(--white)"/> Add Certificate*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>}
            {props.group == "school_year_calendar" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
                <div className="float-right-child add-button" onClick={() => addSchoolYear()}>
                    <AddCircle classname="sidebar-icon" color="var(--white)"/> Create School Year
                </div>
            </div>}
            {props.group == "school_year_calendar_teachers" && <div className="headings">
                <span className="heading-title float-left-child">
                    {props.title}
                </span>
            </div>}
            {props.group == "calendar_landing" && <div className="headings">
                <span className="heading-title float-left-child" onClick={() => viewDashboard()}>
                    <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
                </span>
                <div className="float-right-child add-button" onClick={() => viewCalendar()}>
                    View Calendar
                </div>
            </div>}
            {props.group == "client_user_list" && <div className="headings">
                <span className="heading-title float-left-child" onClick={() => viewClients()}>
                    <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
                </span>
                {/*<div className="float-right-child add-button" onClick={() => editAdvisor(props.client_id)}>*/}
                {/*    <AddCircle classname="sidebar-icon" color="var(--white)"/> Edit Trusted Advisor*/}
                {/*</div>*/}
                <div className="float-right-child add-button" onClick={() => addAdvisor(props.client_id)}>
                    <AddCircle classname="sidebar-icon" color="var(--white)"/> Add Trusted Advisor
                </div>
            </div>}
            {props.group == "teacher_edit" && <div className="headings">
                <span className="heading-title float-left-child" onClick={() => returnTeacher()}>
                    <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
                </span>
            </div>}
            {props.group == "letters" && <div className="headings-multi-items">
                <div className="row background-2">
                    <div className="col-1 padding-left-unset" onClick={() => templateReturn("1")}>
                        <div className={props.type == "1" ? "heading-title headings-letters heading-underline":"heading-title headings-letters"}>
                            Templates
                        </div>
                    </div>
                    <div className="col-1"/>
                    <div className="col-2 padding-left-unset padding-right-1" onClick={() => templateReturn("2")}>
                        <div  className={props.type == "2" ? "heading-title headings-letters heading-underline":"heading-title headings-letters"}>
                            Letters
                        </div>
                    </div>
                    <div className="col-8 padding-right-unset">
                        {props.type == 1 &&
                            <div className="add-button headings-letters header-button float-right-child" onClick={() => createTemplate()}>
                                <AddCircle classname="sidebar-icon" color="var(--white)"/> Create Template
                            </div>}
                        {props.type == 2 &&
                            <div className="add-button headings-letters header-button float-right-child" onClick={() => sendLettersNotices()}>
                                <Send classname="sidebar-icon" color="var(--white)"/> Send Letters
                            </div>}
                    </div>
                </div>
            </div>}
            {props.group == "processes" && <div className="headings-multi-items">
                <div className="row background-2">
                    <div className="col-1 padding-left-unset" onClick={() => templateReturn("1")}>
                        <div className={props.type == "1" ? "heading-title headings-letters heading-underline":"heading-title headings-letters"}>
                            Templates
                        </div>
                    </div>
                    <div className="col-1"/>
                    <div className="col-2 padding-left-unset padding-right-2" onClick={() => templateReturn("2")}>
                        <div  className={props.type == "2" ? "heading-title headings-letters heading-underline":"heading-title headings-letters"}>
                            Processes
                        </div>
                    </div>
                    <div className="col-8 padding-right-unset">
                        {props.type == 1 &&
                            <div className="add-button headings-letters header-button float-right-child" onClick={() => createProcesses()}>
                                <AddCircle classname="sidebar-icon" color="var(--white)"/> Create Template
                            </div>}
                        {props.type == 2 &&
                            <div className="add-button headings-letters header-button float-right-child" onClick={() => sendProcess()}>
                                <Processes classname="sidebar-icon" color="var(--white)"/> Start Process
                            </div>}
                    </div>
                </div>
            </div>}

            {props.group == "doc_add" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "certificate_add" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "certificate_edit" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
                <span className="heading-title float-right-child">
                Import Date : {props.import_date}
            </span>
            </div>}
            {props.group == "doc_edit" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
                <span className="heading-title float-right-child">
                Import Date : {props.import_date}
                </span>
            </div>}
            {props.group == "create_school_year" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "edit_school_year" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            <span className="heading-title float-right" onClick={headingDelete}>
                <button className="button-solid button-transparent-active button-transparent-active-red"
                        // onClick={onReset}
                > <Trash classname="sidebar-icon mr-5" color="var(--red)"/> Delete
                                                    </button>
            </span>
            </div>}
            {props.group == "create_new_template" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "upcoming_landing" && <div className="headings">
            <span className="heading-title float-left-child" onClick={viewDashboard}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "send-letters" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "super_admin_add" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            </div>}
            {props.group == "client_edit" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            <span className="heading-title float-right" onClick={headingDeleteClient}>
                <button className="button-solid button-transparent-active button-transparent-active-red"
                    // onClick={onReset}
                > <Trash classname="sidebar-icon mr-5" color="var(--red)"/> Delete
                                                    </button>
            </span>
            </div>}
            {props.group == "edit_trusted_advisor" && <div className="headings">
            <span className="heading-title float-left-child" onClick={headingReturn}>
                <LeftArrow classname="sidebar-icon mr-5" color="var(--red)"/> {props.title}
            </span>
            <span className="heading-title float-right" onClick={headingDeleteAdvisor}>
                <button className="button-solid button-transparent-active button-transparent-active-red"
                    // onClick={onReset}
                > <Trash classname="sidebar-icon mr-5" color="var(--red)"/> Delete
                                                    </button>
            </span>
            </div>}
        </>
    );
}

export default Heading;